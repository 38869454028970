<template>
  <!-- Manage Generate Shipment Label Modal -->
  <div
    class="modal fade modal-xl modal-fullscreen"
    id="shipment_modal"
    tabindex="-1"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Modify &amp; Download Shipment Label #{{ formData.order_product_id }}</h5>
          <button
            type="button"
            class="btn-close"
            id="groupclosebutton"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body" v-loading="formData.loading">
          <el-form
            @submit.prevent="generateShipment()"
            :model="formData"
            ref="shipmentRef"
            class="w-100"
            :loading="formData.loading"
          >
            <div class="row g-9">
              <div class="col-md-12 fv-row">
                <div class="mb-6 text-danger">
                  Note: Fedex & UPS shipping label will be generated with Test account details.
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <label class="required fs-6 fw-bold mb-2 form-label">{{ 'Shipping Name' }}</label>
                <el-form-item
                  prop="shipping_name"
                  :rules="{
                    required: true,
                    message: 'Shipping name is required',
                    trigger: 'blur',
                  }"
                  >
                <el-input v-model="formData.shipping_name" type="text" placeholder="Shipping Name"/>
                </el-form-item>
              </div>
              <div class="col-md-4">
                <label class="fs-6 fw-bold mb-2 form-label">{{ 'Shipping Company' }}</label>
                <el-form-item prop="shipping_company">
                <el-input v-model="formData.shipping_company" type="text" placeholder="Shipping Company"/>
                </el-form-item>
              </div>
              <div class="col-md-4">
                <label class="required fs-6 fw-bold mb-2 form-label">{{ 'Shipping City' }}</label>
                <el-form-item
                  prop="shipping_city"
                  :rules="{
                    required: true,
                    message: 'Shipping city is required',
                    trigger: 'blur',
                  }"
                  >
                <el-input v-model="formData.shipping_city" type="text" placeholder="Shipping City"/>
                </el-form-item>
              </div>
              <div class="col-md-4">
                <label class="required fs-6 fw-bold mb-2 form-label">{{ 'Shipping State' }}</label>
                <el-form-item
                  prop="shipping_state"
                  :rules="{
                    required: true,
                    message: 'Shipping state is required',
                    trigger: 'blur',
                  }"
                  >
                <el-input v-model="formData.shipping_state" type="text" placeholder="Shipping State"/>
                </el-form-item>
              </div>
              <div class="col-md-4">
                <label class="required fs-6 fw-bold mb-2 form-label">{{ 'Shipping State Code' }}</label>
                  <el-form-item prop="state_code"
                    :rules="{
                      required: true,
                      message: ordersConstants.STATE_CODE_REQUIRED,
                      trigger: 'blur',
                    }">
                    <el-input v-model="formData.state_code" type="text" placeholder="State code"/>
                  </el-form-item>
              </div>
              <div class="col-md-4">
                <label class="required fs-6 fw-bold mb-2 form-label">{{ 'Shipping Country' }}</label>
                <el-input v-model="formData.shipping_country" type="text" placeholder="Shipping Country"/>
              </div>
              <div class="col-md-4">
                <label class="required fs-6 fw-bold mb-2 form-label">{{ 'Shipping Country Code' }}</label>
                  <el-form-item
                    prop="country_code"
                    :rules="{
                      required: true,
                      message: ordersConstants.COUNTRY_CODE_REQUIRED,
                      trigger: 'blur',
                    }"
                  >
                    <el-input v-model="formData.country_code" type="text" placeholder="Country code"/>
                  </el-form-item>
              </div>
              <div class="col-md-4">
                <label class="required fs-6 fw-bold mb-2 form-label">{{ 'Shipping Postcode' }}</label>
                <el-form-item
                  prop="shipping_postcode"
                  :rules="{
                    required: true,
                    message: 'Shipping postcode is required',
                    trigger: 'blur',
                  }"
                  >
                <el-input v-model="formData.shipping_postcode" type="text" placeholder="Shipping Postcode"/>
                </el-form-item>
              </div>
              <div class="col-md-4">
                <label class="required fs-6 fw-bold mb-2 form-label">{{ 'Shipping Phone' }}</label>
                <el-form-item
                  prop="shipping_phone"
                  :rules="{
                    required: true,
                    message: 'Shipping phone is required',
                    trigger: 'blur',
                  }"
                  >
                <el-input v-model="formData.shipping_phone" type="text" placeholder="Shipping Phone"/>
                </el-form-item>
              </div>
              <div class="col-md-4">
                <label class="required fs-6 fw-bold mb-2 form-label">{{ 'Shipping Address1' }}</label>
                <el-form-item
                  prop="shipping_address1"
                  :rules="{
                    required: true,
                    message: 'Shipping address1 is required',
                    trigger: 'blur',
                  }"
                  >
                <el-input v-model="formData.shipping_address1" type="text" placeholder="Shipping Address1"/>
                </el-form-item>
              </div>
              <div class="col-md-4">
                <label class="fs-6 fw-bold mb-2 form-label">{{ 'Shipping Address2' }}</label>
                <el-form-item prop="shipping_address2">
                <el-input v-model="formData.shipping_address2" type="text" placeholder="Shipping Address2"/>
                </el-form-item>
              </div>
            </div>
            <div class="row g-9">
              <div class="col-md-4 fv-row">
                <div class="mb-6">
                  <label class="required fs-6 fw-bold mb-2 form-label">{{
                    ordersConstants.SHIPPING_METHOD
                  }}</label>
                  <el-form-item
                    prop="shipping_method_id"
                    :rules="{
                      required: true,
                      message: ordersConstants.SHIIPING_METHOD_REQUIRED,
                      trigger: 'blur',
                    }"
                  >
                  <el-select v-model="formData.shipping_method_id" placeholder="Select Shipping Method" class="w-100" size="large" @change="getShippingType(formData.shipping_method_id)">
                    <el-option :label="methodVal.shipping_method" :value="methodVal.shipping_method_id" :key="methodVal.shipping_method_id"  v-for="(methodVal, methodKey) in shippingMethod"></el-option>
                  </el-select>
                  </el-form-item>
                </div>
              </div>
              <div class="col-md-4 fv-row">
                <div class="mb-5">
                  <label class="required fs-6 fw-bold mb-2">{{
                    ordersConstants.SHIPPING_TYPE
                  }}</label>
                  <el-form-item prop="shipping_type_id"
                    :rules="{
                      required: true,
                      message: 'Shipping type is required',
                      trigger: 'blur',
                    }"
                  >
                    <el-select v-model="formData.shipping_type_id" placeholder="Select Shipping Type" class="w-100" size="large" @change=setshippingType(formData.shipping_type_id)>
                      <el-option :label="typeVal.shipping_type" :value="typeVal.shipping_type_id" :key="typeVal.shipping_type_id"  v-for="(typeVal, typeKey) in shippingType"></el-option>
                    </el-select>
                  </el-form-item>
                </div>
              </div>
              <div class="col-md-4 fv-row">
                <div class="mb-5">
                  <label class="fs-6 fw-bold mb-2">Tracking No</label>
                  <el-form-item prop="tracking_no">
                    <el-input
                      v-model="formData.tracking_no"
                      type="text"
                      placeholder="Tracking No"
                    />
                  </el-form-item>
                </div>
              </div>
            </div>
            <div class="row g-9" v-if="formData.internal_packages">
              <label
                class="required fs-6 fw-bold mb-2"
                >
                {{ packageConstants.INTERNAL_PACKAGE }}</label
              >
              <div
                class="col-md-12 fv-row mt-0"
                >
                <div class="mb-5">
                  <div class="row">
                    <div class="col-md-2">
                      <label
                        class="fs-6 fw-bold"
                      >
                        {{ packageConstants.WEIGHT_UNIT }}
                      </label>
                    </div>
                    <div class="col-md-2">
                      <label
                        class="fs-6 fw-bold"
                      >
                        {{ ordersConstants.WEIGHT }}
                      </label>
                    </div>
                    <div class="col-md-2">
                      <label
                        class="fs-6 fw-bold"
                      >
                        {{ packageConstants.LENGTH_UNIT }}
                      </label>
                    </div>
                    <div class="col-md-2">
                      <label
                        class="fs-6 fw-bold"
                      >
                        {{ packageConstants.LENGTH }}
                      </label>
                    </div>
                    <div class="col-md-2">
                      <label
                        class="fs-6 fw-bold"
                      >
                        {{ generalConstants.HEIGHT }}
                      </label>
                    </div>
                    <div class="col-md-2">
                      <label
                        class="fs-6 fw-bold"
                      >
                        {{ generalConstants.WIDTH }}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="col-md-12 fv-row mt-0"
                v-for="(internal_package, index) in formData.internal_packages"
                :key="index"
              >
                <div class="mb-5">
                  <div class="row">
                    <div class="col-md-2">
                      <el-form-item
                      class="mb-1"
                      :prop="'internal_packages.' + index + '.weight_class'"
                      :rules="{
                        required: true,
                        message: packageConstants.REQUIRED,
                        trigger: 'change',
                      }">
                        <el-select
                        v-model="internal_package.weight_class"
                        :placeholder="packageConstants.CHOOSE_WEIGHT"
                        size="large"
                        class="w-100">
                          <el-option
                          :label="weightval"
                          :value="weightkey"
                          :key="weightkey"
                          v-for="(weightval, weightkey) in weightClass.value"
                          ></el-option>
                        </el-select>
                      </el-form-item>
                    </div>
                    <div class="col-md-2">
                      <el-form-item
                      class="mb-1"
                      :prop="'internal_packages.' + index + '.weight'"
                      :rules="{
                        required: true,
                        message: packageConstants.REQUIRED,
                        trigger: 'change',
                      }">
                        <el-input
                          v-model="internal_package.weight"
                          :placeholder="ordersConstants.WEIGHT"
                          type="number"
                          min="0"
                          size="large"
                        />
                      </el-form-item>
                    </div>
                    <div class="col-md-2">
                      <el-form-item
                      class="mb-1"
                      :prop="'internal_packages.' + index + '.length_class'"
                      :rules="{
                        required: true,
                        message: packageConstants.REQUIRED,
                        trigger: 'change',
                      }">
                        <el-select
                        v-model="internal_package.length_class"
                        :placeholder="packageConstants.CHOOSE_LENGTH"
                        size="large"
                        class="w-100">
                          <el-option
                          :label="lengthval"
                          :value="lengthkey"
                          :key="lengthkey"
                          v-for="(lengthval, lengthkey) in lengthClass.value"
                          ></el-option>
                        </el-select>
                      </el-form-item>
                    </div>
                    <div class="col-md-2">
                      <el-form-item
                      class="mb-1"
                      :prop="'internal_packages.' + index + '.length'"
                      :rules="{
                        required: true,
                        message: packageConstants.REQUIRED,
                        trigger: 'change',
                      }">
                        <el-input
                          v-model="internal_package.length"
                          :placeholder="packageConstants.LENGTH"
                          type="number"
                          min="0"
                          size="large"
                        />
                      </el-form-item>
                    </div>
                    <div class="col-md-2">
                      <el-form-item
                      class="mb-1"
                      :prop="'internal_packages.' + index + '.height'"
                      :rules="{
                        required: true,
                        message: packageConstants.REQUIRED,
                        trigger: 'change',
                      }">
                        <el-input
                          v-model="internal_package.height"
                          :placeholder="generalConstants.HEIGHT"
                          type="number"
                          min="0"
                          size="large"
                        />
                      </el-form-item>
                    </div>
                    <div class="col-md-2">
                      <el-form-item
                      class="mb-1"
                      :prop="'internal_packages.' + index + '.width'"
                      :rules="{
                        required: true,
                        message: packageConstants.REQUIRED,
                        trigger: 'change',
                      }">
                        <el-input
                          v-model="internal_package.width"
                          :placeholder="generalConstants.WIDTH"
                          type="number"
                          min="0"
                          size="large"
                        />
                      </el-form-item>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row g-9" v-if="formData.external_packages">
              <label
                class="required fs-6 fw-bold mb-2"
                >
                {{ packageConstants.EXTERNAL_PACKAGE }}</label
              >
              <div
                class="col-md-12 fv-row mt-0"
                >
                <div class="mb-5">
                  <div class="row">
                    <div class="col-md-2">
                      <label
                        class="fs-6 fw-bold"
                      >
                        {{ packageConstants.WEIGHT_UNIT }}
                      </label>
                    </div>
                    <div class="col-md-2">
                      <label
                        class="fs-6 fw-bold"
                      >
                        {{ ordersConstants.WEIGHT }}
                      </label>
                    </div>
                    <div class="col-md-2">
                      <label
                        class="fs-6 fw-bold"
                      >
                        {{ packageConstants.LENGTH_UNIT }}
                      </label>
                    </div>
                    <div class="col-md-2">
                      <label
                        class="fs-6 fw-bold"
                      >
                        {{ packageConstants.LENGTH }}
                      </label>
                    </div>
                    <div class="col-md-2">
                      <label
                        class="fs-6 fw-bold"
                      >
                        {{ generalConstants.HEIGHT }}
                      </label>
                    </div>
                    <div class="col-md-2">
                      <label
                        class="fs-6 fw-bold"
                      >
                        {{ generalConstants.WIDTH }}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="col-md-12 fv-row mt-0"
                v-for="(external_package, index) in formData.external_packages"
                :key="index"
              >
                <div class="mb-5">
                  <div class="row">
                    <div class="col-md-2">
                      <el-form-item
                      class="mb-1"
                      :prop="'external_packages.' + index + '.weight_class'"
                      :rules="{
                        required: true,
                        message: packageConstants.REQUIRED,
                        trigger: 'change',
                      }">
                        <el-select
                        v-model="external_package.weight_class"
                        :placeholder="packageConstants.CHOOSE_WEIGHT"
                        size="large"
                        class="w-100">
                          <el-option
                          :label="weightval"
                          :value="weightkey"
                          :key="weightkey"
                          v-for="(weightval, weightkey) in weightClass.value"
                          ></el-option>
                        </el-select>
                      </el-form-item>
                    </div>
                    <div class="col-md-2">
                      <el-form-item
                      class="mb-1"
                      :prop="'external_packages.' + index + '.weight'"
                      :rules="{
                        required: true,
                        message: packageConstants.REQUIRED,
                        trigger: 'change',
                      }">
                        <el-input
                          v-model="external_package.weight"
                          :placeholder="ordersConstants.WEIGHT"
                          type="number"
                          min="0"
                          size="large"
                        />
                      </el-form-item>
                    </div>
                    <div class="col-md-2">
                      <el-form-item
                      class="mb-1"
                      :prop="'external_packages.' + index + '.length_class'"
                      :rules="{
                        required: true,
                        message: packageConstants.REQUIRED,
                        trigger: 'change',
                      }">
                        <el-select
                        v-model="external_package.length_class"
                        :placeholder="packageConstants.CHOOSE_LENGTH"
                        size="large"
                        class="w-100">
                          <el-option
                          :label="lengthval"
                          :value="lengthkey"
                          :key="lengthkey"
                          v-for="(lengthval, lengthkey) in lengthClass.value"
                          ></el-option>
                        </el-select>
                      </el-form-item>
                    </div>
                    <div class="col-md-2">
                      <el-form-item
                      class="mb-1"
                      :prop="'external_packages.' + index + '.length'"
                      :rules="{
                        required: true,
                        message: packageConstants.REQUIRED,
                        trigger: 'change',
                      }">
                        <el-input
                          v-model="external_package.length"
                          :placeholder="packageConstants.LENGTH"
                          type="number"
                          min="0"
                          size="large"
                        />
                      </el-form-item>
                    </div>
                    <div class="col-md-2">
                      <el-form-item
                      class="mb-1"
                      :prop="'external_packages.' + index + '.height'"
                      :rules="{
                        required: true,
                        message: packageConstants.REQUIRED,
                        trigger: 'change',
                      }">
                        <el-input
                          v-model="external_package.height"
                          :placeholder="generalConstants.HEIGHT"
                          type="number"
                          min="0"
                          size="large"

                        />
                      </el-form-item>
                    </div>
                    <div class="col-md-2">
                      <el-form-item
                      class="mb-1"
                      :prop="'external_packages.' + index + '.width'"
                      :rules="{
                        required: true,
                        message: packageConstants.REQUIRED,
                        trigger: 'change',
                      }">
                        <el-input
                          v-model="external_package.width"
                          :placeholder="generalConstants.WIDTH"
                          type="number"
                          min="0"
                          size="large"
                        />
                      </el-form-item>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </el-form>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-primary"
            @click="generateShipment(shipmentRef)"
          >
            {{ generalConstants.SAVE }}
          </button>
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            {{ generalConstants.CANCEL }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, reactive, onMounted } from "vue";
import ApiService from "@/core/services/ApiService";
import { notificationFire } from "@/composable/notification.js";
import globalConstant from "@/core/data/globalConstant.js";
import { checkNumber } from "@/composable/common-validation.js";
import axios from "axios";

export default {
  name: "generate-shipment-modal",
  components: {},
  props: {},
  setup(props, {emit}) {
    const generalConstants = globalConstant.general;
    const ordersConstants = globalConstant.orders;
    const packageConstants = globalConstant.package;
    const shipmentRef = ref(null);
    const shippingMethod = ref([]);
    const shippingType = ref([]);
    const weightClass = reactive({});
    const lengthClass = reactive({});
    const formData = reactive({
      loading: false,
      trading_partner_id: 0,
      customer_name: '',
      order_shipment_id: 0,
      order_id: 0,
      order_product_id: 0,
      shipping_method_id: '',
      shipping_method: '',
      shipping_type_id: '',
      shipping_type: '',
      total_weight: 0,
      tracking_no: '',
      state_code: '',
      country_code: '',
      sku_weight: '',
      shipping_name: '',
      shipping_company: '',
      shipping_city: '',
      shipping_state: '',
      shipping_country: '',
      shipping_postcode: '',
      shipping_phone: '',
      shipping_address1: '',
      shipping_address2: '',
      internal_packages: [],
      external_packages: [],
      updateAddress: 0
    });

    // Get shipping method list
    const getShippingMethod = async() => {
      await ApiService.query("get-shipping-method")
      .then(({ data }) => {
        shippingMethod.value = data;
      })
      .catch((error) => {
        let response = error.response;
        if (response) {
          let message = response.data.message;
          notificationFire(message, "error");
        }
      });
    }

    // Get shipping type list
    const getShippingType = async(shippingMethodId) => {
      shippingMethod.value.map(function (value){
        if (value.shipping_method_id == shippingMethodId) {
          formData.shipping_method = value.shipping_method;
          return false;
        }
      });
      formData.shipping_type_id = '';
      shippingType.value = [];
      await ApiService.query("get-shipping-type/" + shippingMethodId)
      .then(({ data }) => {
        shippingType.value = data;
      })
      .catch((error) => {
        let response = error.response;
        if (response) {
          let message = response.data.message;
          notificationFire(message, "error");
        }
      });
    }

    const setshippingType = (shippingTypeId) => {
      shippingType.value.map(function (value){
        if (value.shipping_type_id == shippingTypeId) {
          formData.shipping_type = value.shipping_type;
          return false;
        }
      });
    }

    // Get Weight Unit
    const getWeightClass = async() => {
      await ApiService.query("get-weight-class")
      .then(({ data }) => {
        weightClass.value = data;
      })
      .catch((error) => {
        let response = error.response;
        if (response) {
          let status = response.status;
          if (status == 404) {
            router.push({ name: "add-master-package" });
          }
        } 
      });
    }

    // Get Length Unit
    const getLengthClass = async() => {
      await ApiService.query("get-length-class")
      .then(({ data }) => {
        lengthClass.value = data;
      })
      .catch((error) => {
        let response = error.response;
        if (response) {
          let status = response.status;
          if (status == 404) {
            router.push({ name: "add-master-package" });
          }
        } 
      });
    }

    const prepareshipmentData = async(productData, isDownload) => {
      if (productData.product_quantity > 0) {
        formData.loading = true;
        await ApiService.query("order-shipment/" + productData.product_id)
        .then(({ data }) => {
          formData.trading_partner_id = productData.trading_partner_id;
          formData.customer_name = productData.customer_name;
          formData.order_shipment_id = data.data ? data.data.order_shipment_id : 0;
          formData.order_id = data.data ? data.data.order_id : productData.order_id;
          formData.order_product_id = data.data ? data.data.order_product_id : productData.product_id;
          formData.shipping_method = data.data ? data.data.shipping_method : productData.shipping_method;
          formData.shipping_type = data.data ? data.data.shipping_type : productData.shipping_type;
          formData.state_code = productData.state_code;
          formData.country_code = productData.country_code;
          formData.sku_weight = productData.sku_weight;

          formData.shipping_name = productData.name;
          formData.shipping_company = productData.company;
          formData.shipping_city = productData.city;
          formData.shipping_state = productData.state;
          formData.shipping_country = productData.country;
          formData.shipping_postcode = productData.postcode;
          formData.shipping_phone = productData.phone;
          formData.shipping_address1 = productData.address1;
          formData.shipping_address2 = productData.address2;

          let productWeight = formData.sku_weight ? Number(formData.sku_weight) : Number(productData.product_weight);
          let totalWeight = 0;
          let packageArr = data.data ? data.data.order_shipment_packages : productData.shipping_package;
          if(packageArr != null) {
            if(packageArr.internal_package != null) {
              formData.internal_packages = [];
              for(let i = 0; i < productData.product_quantity; i++) {
                formData.internal_packages[i] = {
                  order_shipment_packages_id: data.data ? packageArr.internal_package[i].order_shipment_packages_id : 0,
                  package_type: 0,
                  tracking_no: data.data ? packageArr.internal_package[i].tracking_no : null,
                  weight_class: data.data ? packageArr.internal_package[i].weight_class : packageArr.internal_package.weight_class,
                  weight: data.data ? packageArr.internal_package[i].weight : (Number(packageArr.internal_package.box_weight) + productWeight),
                  length_class: data.data ? packageArr.internal_package[i].length_class : packageArr.internal_package.length_class,
                  length: data.data ? packageArr.internal_package[i].package_length : packageArr.internal_package.package_length,
                  height: data.data ? packageArr.internal_package[i].package_height : packageArr.internal_package.package_height,
                  width: data.data ? packageArr.internal_package[i].package_width : packageArr.internal_package.package_width
                }
              }
            } else {
              formData.internal_packages = null;
            }
            if(packageArr.external_package != null) {
              formData.external_packages = [];
              let extPackageWeight = 0;
              for(let j = 0; j < productData.product_quantity; j++) {
                extPackageWeight = (packageArr.internal_package != null) ? (Number(packageArr.external_package.box_weight) + Number(formData.internal_packages[0].weight)) : (Number(packageArr.external_package.box_weight) + productWeight);
                formData.external_packages[j] = {
                  order_shipment_packages_id: data.data ? packageArr.external_package[j].order_shipment_packages_id : 0,
                  package_type: 1,
                  tracking_no: data.data ? packageArr.external_package[j].tracking_no : null,
                  weight_class: data.data ? packageArr.external_package[j].weight_class : packageArr.external_package.weight_class,
                  weight: data.data ? packageArr.external_package[j].weight : extPackageWeight,
                  length_class: data.data ? packageArr.external_package[j].length_class : packageArr.external_package.length_class,
                  length: data.data ? packageArr.external_package[j].package_length : packageArr.external_package.package_length,
                  height: data.data ? packageArr.external_package[j].package_height : packageArr.external_package.package_height,
                  width: data.data ? packageArr.external_package[j].package_width : packageArr.external_package.package_width
                }
                totalWeight += data.data ? Number(packageArr.external_package[j].weight) : Number(extPackageWeight);
              }
            }
          } else {
            formData.internal_packages = null;
            formData.external_packages = [];
            for(let k = 0; k < productData.product_quantity; k++) {
              formData.external_packages[k] = {
                order_shipment_packages_id: 0,
                package_type: 1,
                weight: '',
                length: '',
                height: '',
                width: ''
              }
            }
          }
          formData.total_weight = totalWeight;
          formData.loading = false;
          if(isDownload) {
            downloadShipmentLabel(formData, true);
          }
        })
        .catch((error) => {
          formData.loading = false;
          let message='';
          if (typeof (error.response) !== 'undefined') {
              message = error.response.data.message;
          } else if (typeof (error.message) !== 'undefined') {
              message = error.message;
          } else {
              message = generalConstants.NOTIFY_WRONG_MESSAGE;
          }
          notificationFire(message, generalConstants.NOTIFY_ERROR);
        });
      } else {
        notificationFire('Because the order product quantity was zero, it was not possible to generate a shipment label', "error");
      }
    };

    const generateShipment = (shipmentRef) => {
      shipmentRef.validate((valid) => {
        if (!valid) return false;
        formData.updateAddress = 1;
        ApiService.post("order-shipment", formData)
          .then(({ data }) => {
            if (data) {
              formData.product_id = formData.order_product_id;
              downloadShipmentLabel(formData, false);
              notificationFire(data.message, generalConstants.NOTIFY_SUCCESS);
              document.getElementById('groupclosebutton').click();
            }
          })
          .catch((error) => {
            let message = "";
            if (typeof error.response !== "undefined") {
              message = error.response.data.message;
            } else if (typeof error.message !== "undefined") {
              message = error.message;
            } else {
              message = generalConstants.NOTIFY_WRONG_MESSAGE;
            }
            notificationFire(message, generalConstants.NOTIFY_ERROR);
          });
      });
    };

    //Download Shipment Label
    const downloadShipmentLabel = (shipmentData, isSave) => {
      if(shipmentData.external_packages.length > 0 && shipmentData.external_packages[0].weight != '') {
        ApiService.post("template-management/download-shipment-label", {
          shipment_data: shipmentData,
          is_save: isSave
        })
        .then(({ data }) => {
          if (data) {
            emit("getDetails");
            let path = process.env.VUE_APP_API_URL;
            path = path.replace('/api/v1', '');
            window.open(path + data.data.file, '_blank');
            notificationFire(data.message, data.data.label_status);
          }
        })
        .catch((error) => {
          let message = "";
          if (typeof error.response !== "undefined") {
            message = error.response.data.message;
          } else if (typeof error.message !== "undefined") {
            message = error.message;
          } else {
            message = generalConstants.NOTIFY_WRONG_MESSAGE;
          }
          notificationFire(message, generalConstants.NOTIFY_ERROR);
        });
      } else {
        notificationFire('Please assign packages to product before download shipment label', "error");
      }
    };

    onMounted(() => {
      getShippingMethod();
      getWeightClass();
      getLengthClass();
    });

    return {
      generalConstants,
      ordersConstants,
      shipmentRef,
      generateShipment,
      shippingMethod,
      checkNumber,
      packageConstants,
      prepareshipmentData,
      formData,
      shippingType,
      getShippingType,
      setshippingType,
      getWeightClass,
      weightClass,
      getLengthClass,
      lengthClass
    };
  },
};
</script>