// SKU validation
export const skuValidate = (rule, value, callback) => {
  if (value.trim() === '') {
    callback(new Error(rule.requiredmessage != undefined ? rule.requiredmessage : 'Please validate this field'))
  } else if (/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/g.test(value)) {
    callback(new Error('Enter [Character or Number] only'))
  } else {
    callback()
  }
}

// Digit validation
export const checkNumber = (rule, value, callback) => {
  if (!value) {
    return callback(new Error(rule.requiredmessage != undefined ? rule.requiredmessage : 'Please validate this field'))
  } else if (!/^[0-9]*\.?[0-9]*$/g.test(value)) {
    callback(new Error('Enter digits only'))
  } else {
    callback()
  }
}

// Digit validation without required field
export const checkNumberWithoutRequired = (rule, value, callback) => {
  if(value != null){
    if (!/^[0-9]*\.?[0-9]*$/g.test(value)) {
      callback(new Error('Enter only digits'))
    } else {
      callback()
    }
  }else{
    callback()
  }
}

//check empty space
export const checkEmptySpace = (rule, value, callback) => {
  if (!value) {
    return callback(new Error(rule.requiredmessage != undefined ? rule.requiredmessage : 'Please validate this field'))
  }
  else if (value.trim() === '') {
    callback(new Error('Space are not allowed'))
  } else {
    callback()
  }
}