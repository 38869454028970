import { ref, onMounted, onUnmounted } from 'vue'

export function createOrderPoductOptions(data) {
  let productAdditionalInfo = [];
  if (!!data["product_options"]) {
    let showChar = 30;
    let ellipsestext = "...";
    if (typeof data['product_options'] == "string") {
      try { 
        let productOptions = JSON.parse(data['product_options']); 
        productOptions.forEach((options, oKey) => {
          let optionTitleHtml = options["option_title"];
          let optionValueHtml = options["option_value"];
          if ( typeof options["option_title"] != "undefined" || typeof options["option_value"] != "undefined" ) {
            if (options["option_title"].length > showChar) {
              optionTitleHtml = options["option_title"].substr(0, showChar) + ellipsestext;
            }
            if (options["option_value"].length > showChar) {
              optionValueHtml = options["option_value"].substr(0, showChar) + ellipsestext;
            }
            productAdditionalInfo[oKey] = {
              option_title: optionTitleHtml,
              option_value: optionValueHtml,
            };
          }
        });
      } catch(e) {
        //Ignore
      }
    }
  }
  return { productAdditionalInfo }
}

export function createOrderPoductFiles(data, temp = false) {
  let productFilesInfo = [];
  if (!!data["downloaded_files"]) {
    if (typeof data["downloaded_files"] == "string" || typeof data["downloaded_files"] == "object" ) {
        try {
          let productFiles = JSON.parse(data["downloaded_files"]);
          if(productFiles['success'] && productFiles['success']['P'+data.product_id] && productFiles['success']['P'+data.product_id]['page1']) {
            let files = productFiles['success']['P'+data.product_id]['page1'];
            let thumb = '';
            let image = '';
            if (temp) {
                thumb = process.env.VUE_APP_API_URL+'../../../orders/P-'+data.order_id+'/'+files.thumb;
                if (/\s/g.test(files.thumb)) {
                    thumb = process.env.VUE_APP_API_URL+'../../../orders/P-'+data.order_id+'/'+files.thumb.replace(/\s/g, "%20");
                }
                image = process.env.VUE_APP_API_URL+'../../../orders/P-'+data.order_id+'/'+files.image;
                if (/\s/g.test(files.image)) {
                    image = process.env.VUE_APP_API_URL+'../../../orders/P-'+data.order_id+'/'+files.image.replace(/\s/g, "%20");
                }
            } else {
                thumb = process.env.VUE_APP_API_URL+'../../../orders/'+data.order_id+'/'+files.thumb;
                if (/\s/g.test(files.thumb)) {
                    thumb = process.env.VUE_APP_API_URL+'../../../orders/'+data.order_id+'/'+files.thumb.replace(/\s/g, "%20");
                }
                image = process.env.VUE_APP_API_URL+'../../../orders/'+data.order_id+'/'+files.image;
                if (/\s/g.test(files.image)) {
                    image = process.env.VUE_APP_API_URL+'../../../orders/'+data.order_id+'/'+files.image.replace(/\s/g, "%20");
                }
            }
            productFilesInfo[0] = thumb;
            productFilesInfo[1] = image;
          }
        } catch (e) {
          //Ignore
        }
      }
  }
  return { productFilesInfo }
}